<template>
    <div class="about">
        <div class="content">
            <div class="bookmark_row">
                <div class="bookmark disable"  @click="changePage(0)">
                     <img class="bookmark_icon" src="@/img/icon/about-1.png">
                    关于我们
                </div>
                <div class="line"></div>
                <div class="bookmark">
                    <img class="bookmark_icon" src="@/img/icon/disclaimer-2.png">
                    免责声明
                </div>
                <div class="line"></div>
                <div class="bookmark disable" @click="changePage(2)">
                   <img class="bookmark_icon" src="@/img/icon/rules-1.png">
                    玩法规则
                </div>
            </div>
            <div class="text_box">
                <h1>免责声明</h1>
                <br>
                <br>
                <p>
                    任何人进入、浏览和使用彩云资讯（www.lottos.cloud）网站即承认已阅读、理解并同意受本使用条款约束，并遵守所有适用的法律和法规。若您不同意遵从本使用条款，请勿使用彩云资讯网站（以下简称"本网站"）。彩云资讯有权随时更新本使用条款，您将受到更新后的使用条款的约束，因此您应经常地访问并了解本网站的使用条款。
                </p>
                <br>
                <br>
                <p>
                    本网站提供的任何内容（包括但不限于数据、文字、图表、图象、声音或录象等）的版权均属于彩云资讯或相关权利人。未经彩云资讯或相关权利人事先的书面许可，您不得以任何方式擅自复制、再造、传播、出版、转帖、改编或陈列本网站的内容。同时，未经彩云资讯书面许可，对于本网站上的任何内容，任何人不得在非彩云资讯所属的服务器上做镜像。任何未经授权使用本网站的行为都将违反相关法律法规以及有关国际公约的规定。
                </p>
                <br>
                <br>
                <p>
                    彩云资讯有权随时改变和更新本网站上的内容，不需要先行通知您本人。
                </p>
                <br>
                <br>
                <p>
                    本网站内容除标明“彩云资讯出品”外，其余内容皆由内容服务提供商（机构）、网友提供，不能保证在任何时候、网站上的所有内容均正确、及时和完整；彩云资讯以及其分支机构、员工、代理以及其他任何代表（以下简称"相关人"）对于本网站内容的任何错误、不准确和遗漏以及使用本网站内容得出的结果都将不承担任何责任。彩云资讯及相关人不能保证您任何时候均可进入、浏览和使用本网站，对本网站和其内容的不能使用和使用错误不承担任何责任。
                </p>
                <br>
                <br>
                <p>
                    任何情况下，彩云资讯及相关人对于进入或使用本网站引起的任何依赖本网站内容而作出的决定或采取的行动不承担任何责任，对进入或使用本网站而产生的任何直接的、间接的、惩罚性的损失或其他任何形式的损失包括但不限于业务中断、数据丢失或利润损失不承担任何责任。
                </p>
                <br>
                <br>
                <p>
                    即使彩云资讯被明确告知可能会发生上述损失。彩云资讯以及相关人对于您在进入、浏览和使用本网站或从本网站下载任何内容而导致病毒或其他破坏性程序对您的电脑系统以及其他任何软件、硬件、IT系统或财产的损害或损失不承担任何责任。
                </p>
                <br>
                <br>
                <p>
                    本网站表述的任何意见均属于作者个人意见，并不代表彩云资讯、彩云资讯运营商或彩云资讯关联公司的意见。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    
    export default {
        name: 'About',
        components: {
        },
        data() {
            return {
                pageUrl: [
                    "about", "disclaimer", "gamerule"
                ],
            }
        },
        computed: {
        },
        methods: {
            changePage (i) {
                this.$router.push(this.pageUrl[i]);
            },
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">
    .about{
        .content {
            width: 1440px;
            height: auto;
            margin: auto;
            margin-top: 0.5rem;
            .bookmark_row{
                display: flex;
                justify-content: left;
                align-items: center;
                display: -webkit-flex;
                -webkit-align-items: center;
                margin-bottom: 40px;
                .bookmark{
                    z-index: 10;
                    cursor: pointer;
                    font-size: 17px;
                    font-weight: bold;
           
                    text-align: center;
                    color: #ff6819;
                    cursor: pointer;
                    .bookmark_icon{
                      width: 22px;
                      vertical-align: text-bottom;
                    }
                }
                .disable{
                    color: #0f2957;
                }
                .line{
                    height: 14px;
                    width: 1px;
                    background-color: #d0d1d3;
                    margin: 0 16px;
                }
            }
            .text_box{
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                background-color: #ffffff;
                margin: auto;
                padding: 0.8rem;
                box-sizing: border-box;
                margin-bottom: 0.8rem;
                border-radius: 20px;
                box-shadow: 0 3px 8px 0 rgba(2, 18, 52, 0.2);
                border: solid 3px #e0d6a7; 
                background: #fff;
                width: 100%;
                h1{
                    font-size: 32px;
                    line-height: 32px;
                }
                p{
                    font-size: 17px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: 30px;
                    text-align: left;
                    color: #646363; 
                }
            }
        }
    }    
</style>